import { useRef, useEffect } from "react";
import { addEffect } from "@react-three/fiber";
import useGame from "../../stores/useGame";
export default function Timer() {
  const time = useRef();

  useEffect(() => {
    const unsubscribeEffect = addEffect(() => {
      const state = useGame.getState();
      let elapsedTime = 0;

      if (state.phase === "playing") {
        elapsedTime = Date.now() - state.startTime;
      } else if (state.phase === "ended") {
        elapsedTime = state.endTime - state.startTime;
      }

      elapsedTime /= 1000;
      elapsedTime = elapsedTime.toFixed(2);

      if (time.current) {
        time.current.textContent = elapsedTime;
      }
    });

    return () => {
      unsubscribeEffect();
    };
  }, []);
  return (
    <div
      className="absolute top-[15%] left-0 w-full text-white text-[6vh] bg-black/30 pt-[5px] text-center font-bold"
      ref={time}
    >
      0.00
    </div>
  );
}
